(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/constants/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/constants/assets/javascripts/constants.js');
"use strict";

const DRAWSTATE_DEFINED = 1;
const DRAWSTATE_OPEN = 2;
const DRAWSTATE_FINALIZED = 20;
const DRAWSTATE_PENDING = 3;
const DRAWSTATE_ERROR = 4;
const DRAWSTATE_EMPTY = 5;
const MAX_RETAILER_DESCRIPTION_LENGTH = 900;
const teamMemberStatus = {
  ACTIVE: 1,
  PENDING_REMOVAL: 2
};
const initStates = {
  WAITING: 0,
  ACTIVE: 1,
  COMPLETE: 2,
  READY: 3
};
const groupTypes = {
  PRIVATE: 1,
  RETAILER: 3
};
const groupTypeNames = {
  PRIVATE: 'PRIVATE',
  RETAILER: 'RETAILER'
};
const convertRoleToTypeOfShare = {
  [groupTypes.PRIVATE]: 'c2c',
  [groupTypes.RETAILER]: 'r2c'
};
const typeByGroup = {
  1: groupTypeNames.PRIVATE,
  3: groupTypeNames.RETAILER
};

const reducerStates = {
  PASSIVE: 'PASSIVE',
  LOADING: 'LOADING',
  ERRORED: 'ERRORED',
  COMPLETED: 'COMPLETED'
};
const constants = {
  DRAWSTATE_DEFINED,
  DRAWSTATE_OPEN,
  DRAWSTATE_FINALIZED,
  DRAWSTATE_PENDING,
  DRAWSTATE_ERROR,
  DRAWSTATE_EMPTY,
  MAX_RETAILER_DESCRIPTION_LENGTH,
  teamMemberStatus,
  initStates,
  groupTypes,
  groupTypeNames,
  convertRoleToTypeOfShare,
  typeByGroup,
  reducerStates
};

setGlobal('svs.components.marketplaceData.constants', constants);

 })(window);